export default {
  wines: {
    count: "You have {count} wines",
    search: {
      label: "search",
      empty: "No wine found",
    },
    empty: "You have no wines added yet",
    addFirstWine: "Add your first wine to start your list",
    add: "Add wine",
  },
  settings: {
    profile: "Your profile",
    about: "About this app",
  },
  login: {
    register: "I don't have an account",
    submit: "Sign in",
  },
  register: {
    name: "Name",
    email: "Email",
    password: "Password",
    verifyPassword: "Verify Password",
    passwordNotMatching: "The passwords do not match each other",
    submit: "Register",
  },
  multiStepForm: {
    next: "Next",
    prev: "Previous",
    finish: "Finish",
  },
};
