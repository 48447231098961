import gql from "graphql-tag";

export const countryFragmentOnWine = gql`
  fragment CountryFragmentOnWine on Wine {
    country {
      countryName
      countryShortCode
    }
  }
`;
