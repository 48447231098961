export default {
  wines: {
    count: "Sie haben {count} Weine",
    search: {
      label: "suchen",
      empty: "Es wurde kein Wein gefunden",
    },
    empty: "Du hast noch keinen Wein erfasst",
    addFirstWine: "Erfasse hier Deinen ersten Wein um die Liste zu beginnen",
    add: "Wein erfassen",
  },
  settings: {
    profile: "Dein Profil",
    about: "Über diese App",
  },
  login: {
    register: "Ich habe keinen Account",
    submit: "Log in",
  },
  register: {
    name: "Name",
    email: "E-Mail",
    password: "Passwort",
    verifyPassword: "Passwort wiederholen",
    passwordNotMatching: "Die Passworte stimmen nicht überein",
    submit: "Registrieren",
  },
  multiStepForm: {
    next: "Weiter",
    prev: "Zurück",
    finish: "Abschliessen",
  },
};
