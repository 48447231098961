<template>
  <div class="flex gap-x-6 rounded bg-white px-3 py-4">
    <div>
      <img
        v-if="wine.images.length"
        class="max-w-32 h-auto max-h-32 w-auto"
        :src="`/files/${wine.images[0].filename}`"
      />
    </div>
    <div class="w-9/12">
      <h2 class="font-bold">
        {{ wine.name }}
        <template v-if="wine.rating"> ({{ wine.rating }} / 100) </template>
      </h2>
      <LabelProperty label="Year" :value="wine.year" />
      <LabelProperty
        v-if="wine.country"
        label="Country"
        :value="wine.country.countryName"
      />
      <LabelProperty
        v-if="wine.price"
        label="Price"
        :value="`${wine.price} ${wine.currency.symbol}`"
      />
    </div>
  </div>
</template>

<script>
import LabelProperty from "@/components/LabelProperty.vue";
export default {
  name: "Wine",
  components: { LabelProperty },
  props: {
    wine: {
      type: Object,
      required: true,
    },
  },
};
</script>
