import gql from "graphql-tag";

export const currencyFragmentOnWine = gql`
  fragment CurrencyFragmentOnWine on Wine {
    currency {
      name
      symbol
    }
  }
`;
