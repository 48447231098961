import gql from "graphql-tag";

export const grapeFragment = gql`
  fragment GrapeFragment on Grape {
    id
    name
  }
`;

export const grapeFragmentOnWine = gql`
  fragment GrapeFragmentOnWine on Wine {
    grapes {
      id
      name
    }
  }
`;
