export default {
  data() {
    return {
      askToInstall: false,
      deferredPrompt: null,
    };
  },
  created() {
    window.addEventListener("beforeinstallprompt", this.installPrompt, {
      once: true,
    });
  },
  methods: {
    installPrompt(e) {
      e.preventDefault();

      // Do not prompt if the user already declined
      if (!localStorage.getItem("noInstall")) {
        this.askToInstall = true;
        this.deferredPrompt = e;
      }
    },
    discardInstall() {
      localStorage.setItem("noInstall", "1");
      this.askToInstall = false;
    },
    async installApp() {
      this.deferredPrompt.prompt();

      const result = await this.deferredPrompt.userChoice;
      if (result.outcome === "dismissed") {
        this.discardInstall();
      } else {
        this.askToInstall = false;
      }
    },
  },
};
