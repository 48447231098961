<template>
  <Teleport to="body">
    <div
      tabindex="-1"
      class="fixed top-0 right-0 left-0 z-50 flex h-full items-center justify-center bg-[#00000077]"
    >
      <div
        class="relative flex h-full w-full max-w-md items-center justify-center overflow-auto md:h-auto"
      >
        <div
          class="dark:bg-gray-700 relative overflow-y-auto overflow-x-hidden rounded-lg bg-white shadow"
        >
          <button
            type="button"
            class="hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-800 absolute top-3 right-2.5 ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 dark:hover:text-white"
            data-modal-toggle="popup-modal"
            @click="close"
          >
            <svg
              aria-hidden="true"
              class="h-5 w-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <span class="sr-only">Close modal</span>
          </button>
          <div class="space-y-6 p-6 text-center">
            <slot />

            <slot name="buttons" :close="close">
              <div class="flex justify-end">
                <button
                  data-modal-toggle="popup-modal"
                  type="button"
                  class="text-gray-500 hover:bg-gray-100 focus:ring-gray-200 border-gray-200 hover:text-gray-900 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:bg-gray-600 dark:focus:ring-gray-600 rounded-lg border bg-white px-5 py-2.5 text-sm font-medium focus:z-10 focus:outline-none focus:ring-4 dark:hover:text-white"
                  @click="close"
                >
                  Close
                </button>
              </div>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script>
export default {
  name: "Modal",
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["update:modelValue"],
  methods: {
    close() {
      this.$emit("update:modelValue", false);
    },
  },
};
</script>

<style scoped></style>
