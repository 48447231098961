<template>
  <div class="flex flex-col items-center fill-current">
    <inline-svg
      :src="img"
      :class="iconClasses"
      class="flex-shrink-1 max-h-full max-w-full"
    />
    <div v-if="text" class="leading-none">{{ text }}</div>
  </div>
</template>

<script>
import InlineSvg from "vue-inline-svg";

export default {
  name: "Icon",
  components: {
    InlineSvg,
  },
  props: {
    icon: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      default: null,
    },
    iconClasses: {
      type: String,
      default: "",
    },
  },
  computed: {
    img() {
      return new URL(`/src/assets/${this.icon}.svg`, import.meta.url);
    },
  },
};
</script>
