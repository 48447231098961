export const isReloaded = () => {
  return window.performance
    .getEntriesByType("navigation")
    .map((nav) => nav.type)
    .includes("reload");
};

export const isOnline = () => {
  return navigator.onLine;
};
