import { createRouter, createWebHistory, RouterView } from "vue-router";
import WinesList from "@/views/Wine/List.vue";
import { getUserAsync } from "@/api/getUser";

const routes = [
  {
    path: "/",
    component: RouterView,
    children: [
      {
        name: "wines",
        path: "",
        component: WinesList,
      },
      {
        name: "wine/new",
        path: "/wine/new/:step?",
        component: () => import("../views/Wine/New.vue"),
        children: [
          {
            path: "scan",
            name: "wine/new/scan",
            component: () => import("../components/BarcodeScanner.vue"),
          },
        ],
        props(route) {
          return {
            ...route.params,
            step: route.params.step
              ? Number.parseInt(route.params.step, 10)
              : 0,
          };
        },
      },
      {
        name: "wine/edit",
        path: "/wine/edit/:id/:step?",
        component: () => import("../views/Wine/New.vue"),
        props(route) {
          return {
            ...route.params,
            step: route.params.step
              ? Number.parseInt(route.params.step, 10)
              : 0,
          };
        },
      },
      {
        name: "wine",
        path: "/wine/:id",
        component: () => import("../views/Wine/Detail.vue"),
      },
    ],
  },
  {
    name: "scan",
    path: "/scan/",
    component: () => import("../views/Scan.vue"),
  },
  {
    name: "relations",
    path: "/relations",
    component: () => import("../views/Relations.vue"),
  },
  {
    name: "settings",
    path: "/settings",
    component: () => import("../views/Settings.vue"),
  },
  {
    name: "register",
    path: "/register",
    component: () => import("../views/Register.vue"),
  },
  {
    name: "login",
    path: "/login",
    component: () => import("../views/Login.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
  linkActiveClass: "text-primary",
});

router.beforeEach(async (to) => {
  try {
    if (to.name !== "login" && to.name !== "register") {
      const { data } = await getUserAsync();
      if (!data.me) {
        return { name: "login" };
      }
    }
  } catch (e) {
    return { name: "login" };
  }
});

export default router;
