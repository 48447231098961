import { createApp, h, provide } from "vue";
import App from "./App.vue";
import router from "./router";
import { DefaultApolloClient } from "@vue/apollo-composable";
import { createMetaManager } from "vue-meta";
import l18n from "./locales";
import { getApolloClient } from "@/api/apollo";
import "./styles.css";

const apolloClient = getApolloClient();

const app = createApp({
  setup() {
    provide(DefaultApolloClient, apolloClient);
  },

  render: () => h(App),
});

app.use(router);
app.use(l18n);
app.use(createMetaManager());
router.isReady().then(() => app.mount("#app"));
