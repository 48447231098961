import gql from "graphql-tag";

export const signUp = gql`
  mutation signUp($name: String!, $email: String!, $password: String!) {
    signUp(name: $name, email: $email, password: $password) {
      id
      email
      avatar
    }
  }
`;

export const signIn = gql`
  mutation signIn($email: String!, $password: String!) {
    signIn(email: $email, password: $password) {
      id
      email
      avatar
    }
  }
`;

export const me = gql`
  query me {
    me {
      id
      email
      avatar
    }
  }
`;
