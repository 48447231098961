import gql from "graphql-tag";

export const storageFragmentOnWine = gql`
  fragment StorageFragmentOnWine on Wine {
    storage {
      id
      amount
      location {
        id
        name
      }
    }
  }
`;
