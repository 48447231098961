import gql from "graphql-tag";
import { countryFragmentOnWine } from "@/graphql/fragments/countryFragment";
import { regionFragmentOnWine } from "@/graphql/fragments/regionFragment";
import { fileFragment } from "@/graphql/fragments/fileFragment";
import { storageFragmentOnWine } from "@/graphql/fragments/StorageFragment";
import { grapeFragmentOnWine } from "@/graphql/fragments/grapeFragment";
import { currencyFragmentOnWine } from "@/graphql/fragments/currencyFragment";

export const wineFragment = gql`
  fragment WineFragment on Wine {
    id
    name
    images {
      ...FileFragment
    }
    attachments {
      ...FileFragment
    }
    description
    color
    bouquet
    taste
    drinkWith
    storagePotential
    servingTemparature
    alcohol
    sugar
    maturation
    awards
    year
    store
    price
    rating
    barcode
    ...GrapeFragmentOnWine
    ...CountryFragmentOnWine
    ...RegionFragmentOnWine
    ...StorageFragmentOnWine
    ...CurrencyFragmentOnWine
  }

  ${grapeFragmentOnWine}
  ${countryFragmentOnWine}
  ${regionFragmentOnWine}
  ${storageFragmentOnWine}
  ${fileFragment},
  ${currencyFragmentOnWine}
`;
