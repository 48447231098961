import { ApolloClient, from, InMemoryCache } from "@apollo/client/core";
import { onError } from "@apollo/client/link/error";
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );

  if (networkError) console.log(`[Network error]: ${networkError}`);
});
const httpLink = createUploadLink({ uri: "/graphql" });

const apolloClient = new ApolloClient({
  link: from([errorLink, httpLink]),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          wines: {
            // Return wine from the list if it is already cached
            read(wines, { args, toReference }) {
              if (args.id) {
                return toReference({
                  __typename: "Wine",
                  id: args.id,
                });
              }
              return wines;
            },
          },
        },
      },
    },
  }),
});

export function getApolloClient() {
  return apolloClient;
}
