import gql from "graphql-tag";
import { wineFragment } from "@/graphql/fragments/wineFragment";

export const getWine = gql`
  query Wines($id: ID, $where: SequelizeJSON) {
    wines(id: $id, where: $where) {
      ...WineFragment
    }
  }

  ${wineFragment}
`;

export const addWine = gql`
  mutation addWine($wine: WineInput) {
    addWine(wine: $wine) {
      ...WineFragment
    }
  }

  ${wineFragment}
`;

export const setWine = gql`
  mutation setWine($id: ID!, $wine: WineUpdate!) {
    setWine(id: $id, wine: $wine) {
      ...WineFragment
    }
  }

  ${wineFragment}
`;

export const deleteWine = gql`
  mutation deleteWine($id: ID!) {
    deleteWine(id: $id) {
      id
    }
  }
`;
