<template>
  <metainfo>
    <template #title="{ content }">
      {{ title(content) }}
    </template>
  </metainfo>
  <div class="flex h-full w-screen flex-col">
    <div class="mx-6 mb-8 mt-16 flex">
      <Logo />
      <router-link v-if="me" to="settings" class="ml-auto">
        <img :src="me.avatar" class="h-16" />
      </router-link>
    </div>
    <div class="flex flex-grow overflow-hidden">
      <div class="flex flex-grow flex-col">
        <router-view v-slot="{ Component }">
          <transition
            enter-active-class="duration-300 ease-out"
            enter-from-class="transform -translate-x-full"
            enter-to-class="translate-x-0"
            leave-active-class="duration-300 ease-in"
            leave-from-class="translate-x-0"
            leave-to-class="transform translate-x-full"
            mode="out-in"
          >
            <component
              :is="Component"
              class="relative w-screen flex-grow flex-col overflow-auto px-6 pb-6"
            />
          </transition>
        </router-view>

        <div
          v-if="loggedIn"
          class="xs:py-3 xs:space-x-6 xs:h-24 z-10 flex h-20 flex-shrink-0 items-end justify-between px-14 pb-5 pt-2 text-xs text-gray shadow-up md:justify-start md:space-x-6"
        >
          <router-link :to="{ name: 'wines' }">
            <Icon icon="wine" text="Weinkeller" class="gap-y-2" />
          </router-link>
          <router-link :to="{ name: 'scan' }" class="mb-2">
            <Icon icon="scan" />
          </router-link>
          <!--        <router-link :to="{ name: 'relations' }" class="block h-full">-->
          <!--          <Icon icon="database" text="Relations" class="h-full gap-y-2" />-->
          <!--        </router-link>-->
          <router-link :to="{ name: 'wine/new' }">
            <Icon
              icon="add"
              text="Hinzufügen"
              class="gap-y-2 text-gray"
              icon-classes="text-primary stroke-current"
            />
          </router-link>
        </div>
      </div>
    </div>
  </div>
  <Modal v-if="needRefresh" v-model="needRefresh">
    <div class="text-left">
      An update is available. Please confirm to update and reload the app.
    </div>
    <template #buttons="{ close }">
      <div class="space-x-6">
        <a class="btn btn-secondary" @click="needRefresh = false">Postpone</a>
        <a class="btn btn-primary" @click="updateServiceWorker">Update</a>
      </div>
    </template>
  </Modal>
  <Modal v-if="askToInstall" v-model="askToInstall">
    <div class="text-left">
      Welcome to Winery!<br />
      This app works best if it is installed. If you discard this, we won't ask
      again.
    </div>
    <template #buttons="{ close }">
      <div class="space-x-6">
        <a
          class="btn btn-secondary"
          @click="
            () => {
              close();
              discardInstall();
            }
          "
          >No thanks</a
        >
        <a class="btn btn-primary" @click="installApp">Install Winery</a>
      </div>
    </template>
  </Modal>
</template>

<script>
import Icon from "./components/Icon.vue";
import Logo from "./components/Logo.vue";
import install from "@/mixins/install";
import { useMeta } from "vue-meta";
import { isOnline, isReloaded } from "./lib/navigation";
import Modal from "@/components/Modal.vue";
import "swiper/css";
import { getUser } from "@/api/getUser";
import { useRegisterSW } from "virtual:pwa-register/vue";

export default {
  components: { Icon, Logo, Modal },
  mixins: [install],
  setup() {
    const { offlineReady, needRefresh, updateServiceWorker } = useRegisterSW();

    console.log("isOnline", isOnline());
    console.log("isReloaded", isReloaded());

    useMeta({
      title: "",
      htmlAttrs: {
        lang: "en",
      },
    });
    const { me } = getUser();
    return { me, offlineReady, needRefresh, updateServiceWorker };
  },
  data() {
    return {
      titleSuffix: "Winery",
    };
  },
  computed: {
    loggedIn() {
      return !!this.me;
    },
  },
  methods: {
    title(title) {
      if (title) {
        return `${title} | ${this.titleSuffix}`;
      }
      return this.titleSuffix;
    },
  },
};
</script>
