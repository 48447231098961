<template>
  <div :class="{ flex: loading }" class="flex">
    <input
      type="text"
      class="mb-12 w-full rounded border-secondary bg-white placeholder-form"
      :placeholder="$t('wines.search.label')"
      @input="searchWine"
    />

    <Spinner v-if="loading" class="mx-auto flex self-center" />
    <div v-else-if="wines.length">
      <h3 class="mb-3">{{ $t("wines.count", { count: wines.length }) }}</h3>

      <div class="space-y-4">
        <Wine
          v-for="wine in wines"
          :key="wine.id"
          :wine="wine"
          @click="openWine(wine.id)"
        />
      </div>
    </div>
    <div
      v-else
      class="flex flex-grow flex-col items-center justify-center space-y-7"
    >
      <template v-if="search">
        {{ $t("wines.search.empty") }}
      </template>
      <template v-else>
        <h1>{{ $t("wines.empty") }}</h1>
        <div>{{ $t("wines.addFirstWine") }}</div>
        <router-link :to="{ name: 'wine/new' }" class="btn btn-primary">
          {{ $t("wines.add") }}
        </router-link>
      </template>
    </div>
  </div>
</template>

<script>
import { useQuery, useResult } from "@vue/apollo-composable";
import Wine from "@/components/Wine.vue";
import { getWine } from "@/graphql/wine";
import { debounce } from "lodash-es";
import Spinner from "@/components/Spinner.vue";

export default {
  name: "Wines",
  components: { Spinner, Wine },
  setup() {
    const { result, refetch, loading } = useQuery(getWine);
    const wines = useResult(result, []);
    return { wines, fetchWines: refetch, loading };
  },
  data() {
    return {
      search: "",
    };
  },
  methods: {
    searchWine: debounce(function (event) {
      this.fetchWines({
        where: JSON.stringify({ name: { like: `%${event.target.value}%` } }),
      });
      this.search = event.target.value;
    }, 250),
    openWine(id) {
      this.$router.push({ name: "wine", params: { id } });
    },
  },
};
</script>
