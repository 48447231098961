import gql from "graphql-tag";

export const regionFragment = gql`
  fragment RegionFragment on Region {
    name
    shortCode
  }
`;
export const regionFragmentOnWine = gql`
  fragment RegionFragmentOnWine on Wine {
    region {
      name
      shortCode
    }
  }
`;
