<template>
  <div v-if="value" class="flex gap-x-6 py-1.5">
    <label :class="labelClass" class="inline-block flex-shrink-0 font-bold">{{
      label
    }}</label>
    <div>{{ value }}</div>
  </div>
</template>

<script>
export default {
  name: "LabelProperty",
  props: {
    value: {
      type: [String, Number],
      default: null,
    },
    label: {
      type: String,
      required: true,
    },
    labelClass: {
      type: [String, Array, Object],
      default: "w-24",
    },
  },
};
</script>
