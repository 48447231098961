import { provideApolloClient, useQuery } from "@vue/apollo-composable";
import { me as meQuery } from "@/graphql/user";
import { computed } from "vue";
import { getApolloClient } from "@/api/apollo";

export function getUser() {
  provideApolloClient(getApolloClient());
  const { result, error, loading } = useQuery(meQuery);
  const me = computed(() => result.value?.me);
  return { me, error, loading };
}

export async function getUserAsync() {
  const client = getApolloClient();
  return client.query({ query: meQuery });
}
