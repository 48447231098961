<template>
  <div class="mx-auto flex self-center">
    <img src="@/assets/loading.svg" />
  </div>
</template>

<script>
export default {
  name: "Spinner",
};
</script>
